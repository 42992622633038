<!-- <app-toolbar></app-toolbar> -->
<!-- <app-linkbar></app-linkbar> -->

<div class="holder" role="main">
  <div class="content">
    <mat-card>
      <mat-card-content>
        <h2>COMING SOON</h2>
      </mat-card-content>
      <!-- <mat-card-actions>
          <button mat-button>Subscribe for Updates</button>
          <button mat-button>Follow on Twitter</button>
          <button mat-button>Like on Facebook</button>
        </mat-card-actions> -->
    </mat-card>
  </div>
</div>

<router-outlet></router-outlet>
